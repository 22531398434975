// GraphPage.js
import React, { useRef } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Graph3DGlow from './Graph3DGlow';
import Graph3DSpace from './Graph3DSpace';
import Graph2D from './Graph2D';
import './GraphPage.css';
import miserablesData from './miserables';  // Import the dataset directly
import blocksData from './blocks';         // Import the dataset directly

const GraphPage = () => {
  const containerRef1 = useRef();
  const containerRef2 = useRef();
  const containerRef3 = useRef();

  return (
    <div className="graph-page-container">
      <Header />
      
      <div id="graph-container1" ref={containerRef1} className="graph-box">
        <Graph3DGlow data={miserablesData} containerRef={containerRef1} />
      </div>

      <div id="graph-container2" ref={containerRef2} className="graph-box">
        <Graph3DSpace data={blocksData} containerRef={containerRef2} />
      </div>

      <div id="graph-container3" ref={containerRef3} className="graph-box">
        <Graph2D data={miserablesData} containerRef={containerRef3} />
      </div>

      <Footer />
    </div>
  );
};

export default GraphPage;
