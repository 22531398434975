// Graph2D.js
import React, { useRef, useEffect, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';

const Graph2D = ({ data, containerRef }) => {
  const fgRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 800, height: 800 });

  // Update graph dimensions when container size changes
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setDimensions({
          width: clientWidth - 20, // 10px padding on both sides
          height: clientHeight - 20, // 10px padding on both sides
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [containerRef]);

  return (
    <>
      {data ? (
        <ForceGraph2D
          ref={fgRef}
          width={dimensions.width}
          height={dimensions.height}
          graphData={data}
          backgroundColor="#fcfcfc"
          nodeAutoColorBy="group"
          nodeLabel="id"
          linkDirectionalParticles="value"
          linkDirectionalParticleSpeed={d => d.value * 0.001}
        />
      ) : (
        <p>Loading graph...</p>
      )}
    </>
  );
};

export default Graph2D;
