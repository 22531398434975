import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './HomePage.css';

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Links to the existing services
  const aiLinks = [
    { to: '/genai', title: 'Generative AI', description: 'Have a conversation with Hubbiot AI' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
  ];
  const authLinks = [
    { to: '/auth', title: 'Auth', description: 'Manage authentication and authorization' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
  ];
  const infraLinks = [
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
  ];
  const digitalTwinLinks = [
    { to: '/graph', title: 'Graph Engine', description: 'Explore data in the Hubbiot Graph Engine' },
    { to: '/ar', title: 'Augmented Reality', description: 'Connect the data with the world around you.' },
    { to: '/configurator', title: 'Graph Configurator', description: 'Configure and model your data landscape.' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
  ];

  const docsLinks = [
    { to: '/api', title: 'API Documentation', description: 'Explore Hubbiot APIs' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
    { to: '/', title: 'TBA', description: 'Service will soon be announced!' },
  ];

  return (
    <div className="homepage-container">
      <Header />

      {/* Welcome text outside the container */}
      <div className="welcome-text">
        <h2>Welcome to Hubbiot Console</h2>
      </div>

      {/* Search bar at the top left */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search tools..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>

      <div className="content-wrapper">

      <div className="portal-container twin-container">
          <h2>Digital Twin</h2>
          {digitalTwinLinks
            .filter(link => link.title.toLowerCase().includes(searchTerm))
            .map((link, index) => (
              <a className="portal-link" href={link.to} key={index}>
                <div>
                  <strong>{link.title}</strong>
                  <p>{link.description}</p>
                </div>
              </a>
            ))}
        </div>

        <div className="portal-container ai-container">
          <h2>Generative AI</h2>
          {aiLinks
            .filter(link => link.title.toLowerCase().includes(searchTerm))
            .map((link, index) => (
              <a className="portal-link" href={link.to} key={index}>
                <div>
                  <strong>{link.title}</strong>
                  <p>{link.description}</p>
                </div>
              </a>
            ))}
        </div>

        <div className="portal-container auth-container">
          <h2>Identity and Access</h2>
          {authLinks
            .filter(link => link.title.toLowerCase().includes(searchTerm))
            .map((link, index) => (
              <a className="portal-link" href={link.to} key={index}>
                <div>
                  <strong>{link.title}</strong>
                  <p>{link.description}</p>
                </div>
              </a>
            ))}
        </div>

        <div className="portal-container infra-container">
          <h2>Infrastructure</h2>
          {infraLinks
            .filter(link => link.title.toLowerCase().includes(searchTerm))
            .map((link, index) => (
              <a className="portal-link" href={link.to} key={index}>
                <div>
                  <strong>{link.title}</strong>
                  <p>{link.description}</p>
                </div>
              </a>
            ))}
        </div>

        <div className="portal-container docs-container">
          <h2>Documentation</h2>
          {docsLinks
            .filter(link => link.title.toLowerCase().includes(searchTerm))
            .map((link, index) => (
              <a className="portal-link" href={link.to} key={index}>
                <div>
                  <strong>{link.title}</strong>
                  <p>{link.description}</p>
                </div>
              </a>
            ))}
        </div>

      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
